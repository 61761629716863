<template>
    <uw-content title="考核计划" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button :type="search.state == null ? 'primary' : 'text'" @click="search.state = null; TableSearch()">全部</el-button>
            <el-button :type="search.state == 0 ? 'primary' : 'text'" @click="search.state = 0; TableSearch()">未开始</el-button>
            <el-button :type="search.state == 1 ? 'primary' : 'text'" @click="search.state = 1; TableSearch()">进行中</el-button>
            <el-button :type="search.state == 2 ? 'primary' : 'text'" @click="search.state = 2; TableSearch()">已归档</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" @click="$refs.planCreate.Open()">新建计划</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input v-model="search.name" placeholder="模板名称" />
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table ref="xTable" :data="table" :loading="loading" height="100%">

                    <!-- 状态 -->
                    <vxe-column width="80" title="状态" align="center">
                        <template #default="{ row }">
                            <el-tag effect="plain" v-if="row.state == 0" type="warning">未开始</el-tag>
                            <el-tag effect="plain" v-if="row.state == 1">进行中</el-tag>
                            <el-tag effect="plain" v-if="row.state == 2" type="info">已归档</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 考核计划名称 -->
                    <vxe-column min-width="180" field="name" title="考核计划名称">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.planDrawer.Open(row.id)">{{ row.name }}</span>
                        </template>
                    </vxe-column>

                    <!-- 考核周期 -->
                    <vxe-column min-width="160" field="name" title="考核周期">
                        <template #default="{ row }">
                            {{ row.date.join(' 至 ') }}
                        </template>
                    </vxe-column>

                    <!-- 考核人数 -->
                    <vxe-column min-width="80" title="考核人数">
                        <template #default="{ row }">
                            {{ row.users_id.length }} / 人
                        </template>
                    </vxe-column>

                    <!-- 考核说明 -->
                    <vxe-column min-width="160" field="describe" title="考核说明"></vxe-column>

                    <vxe-column min-width="80" field="count_wait" title="待考核"></vxe-column>
                    <vxe-column min-width="80" field="count_progress" title="考核中"></vxe-column>
                    <vxe-column min-width="80" field="count_finish" title="已完成"></vxe-column>
                    <vxe-column min-width="80" field="count_reject" title="已驳回"></vxe-column>
                    <vxe-column min-width="80" field="count_close" title="已归档"></vxe-column>

                    <!-- 创建人 -->
                    <vxe-column min-width="120" title="创建人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.created_users.avatar"> {{ row.created_users.name[0] }} </el-avatar>
                                {{  row.created_users.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 更新人 -->
                    <vxe-column min-width="120" title="更新人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.updated_users.avatar"> {{ row.updated_users.name[0] }} </el-avatar>
                                {{  row.updated_users.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <vxe-column min-width="160" field="created_at" title="创建时间" ></vxe-column>
                    <vxe-column min-width="160" field="updated_at" title="更新时间" ></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <hrm-kpi-plan-create ref="planCreate" @onChange="TableSearch()" />
        <hrm-kpi-plan-drawer ref="planDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                state: null,
                users_id: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-plan/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_users', 'updated_users'],
                    hidden: ['template']
                }
            ).then((rps) => {

                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        }
    }
}
</script>